// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

// console.log("### controllers/index.js - loaded")
import { application } from "./application"

// console.log("### controllers/index.js - loading individual controllers")

// Register each controller with Stimulus
import controllers from "./*_controller.js"
controllers.forEach((controller) => {
  // console.log(`controllers/index.js - ${controller.name}`)
  application.register(controller.name, controller.module.default)
})

// Now we can namespace some of the controllers into the future.
// import tableControllers from "./tables/*_controller.js"
// tableControllers.forEach((controller) => {
//   // gsub out tables-- for the controller name
//   const controllerName = controller.name.replace("tables--", "")
//   application.register(controllerName, controller.module.default)
// })

// NOTE: This is how namespaces work here.
// import tableControllers from "./tables/*_controller.js"
// tableControllers.forEach((controller) => {
//   console.log(`controller: ${controller.name}`)
//   // controller: tables--remote-table
//   // controller: tables--table
// })
// I was not able to find a command like above where we can import a bunch of different folders:
// the import command doesn't want to work within a loop

// Need to manually import pages that end in .jsx
import ReactController from "./react_controller"
application.register("react", ReactController)

// Register other folders to keep this folder organized
import folderControllers from "./forms/*_controller.js"
folderControllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

// Register any of our jsx controllers
import ColorPickerController from "./color_picker_controller"
application.register("color-picker", ColorPickerController)

import { Dropdown, Modal, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)
